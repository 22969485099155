const isString = (a: any) => typeof a === 'string';
// Round number to 2 decimal places
export const toDecimal = (n: number, digits: number = 2): string | number => {
    const fallback: number = 0;
    if (!n) return fallback.toFixed(digits);
    if (isString(n)) return Number(n).toFixed(digits);
    return n.toFixed(digits);
};

export const getColorByValue = (value: number | string) => {
    if (value === 0) return '#202124';
    return value < 0 ? '#F84C4C' : '#1e964e';
};

export const getIndicatorByValue = (value: number | string) => {
    if (value > 0) return '+';
    return '';
};

// Util for 1000 to 1,000
export const formatNumberToHumanReadable = (num: number | string) => num?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
