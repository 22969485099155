import api, {fetchAccessTokenAndSetHeaders} from 'Utils/fetchUtils'
import {getAPIHostUrl} from 'Utils/urlUtils'
import {AxiosResponse} from 'axios'

export const initSmallcase = () => {
    fetchAccessTokenAndSetHeaders()
    return api
        .get(`${getAPIHostUrl(true)}/api/v1/smallcase/broker`)
        .then(({data}) => data)
}

export const fetchSmallCaseTransaction = () => {
    fetchAccessTokenAndSetHeaders()
    return api
        .post(
            `${getAPIHostUrl(
                true
            )}/api/v1/smallcase/transaction?mfHoldings=true`,
            {
                intent: 'HOLDINGS_IMPORT',
                version: 'v2'
            }
        )
        .then(({data}) => data)
}

export const fetchSmallcaseHolding = async () => {
    fetchAccessTokenAndSetHeaders()
    const url = `${getAPIHostUrl(true)}/api/v1/smallcase/fetch-raw-holdings`
    const {data} = await api.get<AxiosResponse<any>>(url)
    return data as any
}

export const getHoldingsFromSmallcase = async () => {
    fetchAccessTokenAndSetHeaders()
    const url = `${getAPIHostUrl(true)}/api/v1/smallcase/fetch-holdings`
    const {data} = await api.get<AxiosResponse<any>>(url)
    return data
}
