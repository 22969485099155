import { css } from '@emotion/react';
import { useState } from 'react';

import { ENTITY_ASSET_URL } from 'Constants/constants';

export const EntityImage = ({ isin, name }) => {
    const [isImageError, setisImageError] = useState(false);
    const imgUrl = `${ENTITY_ASSET_URL}/${isin}.png`;

    const handleImageError = (e) => {
        setisImageError(true);
        e.target.style.display = 'none'; // Hide the broken image
    };

    if (isImageError)
        return (
            <div css={imageBoxCSS} className="flex items-center justify-center text-14 font-600">
                {name.charAt(0)}
            </div>
        );
    return (
        <>
            <img src={imgUrl} height={20} width={20} css={imageCSS} onError={handleImageError} />
        </>
    );
};
const imageBoxCSS = css`
    height: 24px;
    width: 24px;
    background: #ededed;
`;
const imageCSS = css`
    height: 24px;
    width: 24px;
`;
