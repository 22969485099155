import { css } from '@emotion/react';
import axios from 'axios';
import Script from 'next/script';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { createTempUser } from '@/services/api/user';

import { Button } from '@/ui/components/button';
import DesktopModalNPhoneScreen from '@/ui/components/desktopModalNPhoneScreen';
import { EntityImage } from '@/ui/components/entityImage';

import { getCookie } from '@/utils/cookie';
import { useTickerData } from '@/utils/hooks/useActionPriceData';
import { formatNumberToHumanReadable, getColorByValue, getIndicatorByValue, toDecimal } from '@/utils/numberUtils';

import { Spinner, TickIcon } from '@/constants/icons';
import { useSmallCaseHoldingImport } from '@/hooks/useSmallcaseholdingImport';
import { ChevronRight } from '@/marketing/constants/icon';
import { AUTH_TOKEN, TEMP_AUTH_TOKEN } from '@utilities/constants/constants';
import { error } from 'console';
import { destroyCookie, setCookie } from 'nookies';

const setAuthCookies = (user) => {
    setCookie(null, 'backend_id', user?.backendId, {
        httpOnly: false,
        path: '/',
    });
    setCookie(null, TEMP_AUTH_TOKEN, user?.backendAuthToken, {
        httpOnly: false,
        path: '/',
    });
    setCookie(null, 'isTempUser', 'true', { httpOnly: false, path: '/' });
};

const deleteTempAuth = () => {
    setCookie(null, TEMP_AUTH_TOKEN, null, { httpOnly: false, path: '/' });
};

export const ImportPortfolioFlow = (props: { channel_id?: string; channel?: string; onClose?: () => void }) => {
    const [hasEventFired, setHasEventFired] = useState(false);
    const isComingFromChannel = useMemo(() => {
        return !!props?.channel;
    }, [props]);

    const onScriptLoad = useCallback(async () => {
        const authTokenCookie = getCookie(AUTH_TOKEN);
        if (!isComingFromChannel && !authTokenCookie) {
            const user = await createTempUser();
            setAuthCookies(user);
        }
        initiateUserImport();
    }, []);

    useEffect(() => {
        const checkForScriptLoad = () => {
            const isScriptLoaded = document.getElementById('smallcase-sdk');

            const isScriptLoadedAndReady = isScriptLoaded && (window as any).scDK;

            if (isScriptLoadedAndReady) {
                onScriptLoad();
                setHasEventFired(true);
            }

            return isScriptLoadedAndReady;
        };

        const isScriptLoadedAndReady = checkForScriptLoad();
        if (isScriptLoadedAndReady) return;

        const intervalId = setInterval(() => {
            const hasScriptLoadedAndReady = checkForScriptLoad();

            if (hasScriptLoadedAndReady) clearInterval(intervalId);
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const {
        initiateUserImport,
        holdingsData: holdingsData,
        smallCaseConnected,
        isFlowLoading,
        smallcaseError,
    } = useSmallCaseHoldingImport({
        callback: (data) => {
            if (isComingFromChannel) {
                axios.get(`${process.env.NEXT_PUBLIC_ROMEO_URL}/api/send_portfolio_sync?channel_user_id=${props?.channel_id}&channel=${props?.channel}`);

                deleteTempAuth();
            }
        },
        onError: (data) => {},
    });

    return (
        <>
            <Script src="https://gateway.smallcase.com/scdk/2.0.0/scdk.js" type="text/javascript" id="smallcase-sdk" />

            <DesktopModalNPhoneScreen closeFn={props.onClose} showClose={!!props.onClose}>
                <div className="flex h-full min-h-full w-full items-center justify-center">
                    {isFlowLoading && (
                        <div className="flex min-h-full w-full flex-col items-center justify-center">
                            <div className="pr-12">
                                <Spinner height={40} width={40} />
                            </div>
                            <div className="mt-12 text-20 font-700 tracking-1">Loading...</div>
                            <div className="mt-8 text-15 tracking-1">Please wait while we connect your portfolio</div>
                        </div>
                    )}

                    {!isFlowLoading && smallcaseError && (
                        <div className="flex min-h-full w-full flex-col items-center justify-center">
                            <div className="mt-12 text-20 font-700 tracking-1 text-red-100">Error</div>
                            <div className="mt-8 px-12 text-center text-15 tracking-1">{smallcaseError}</div>

                            <Button
                                className="mt-20"
                                type="primary"
                                onClick={() => {
                                    initiateUserImport();
                                }}
                            >
                                Try again
                            </Button>
                        </div>
                    )}

                    {smallCaseConnected && !smallcaseError && isComingFromChannel && (
                        <div className="flex min-h-full w-full flex-col items-center justify-center">
                            <TickIcon height={40} width={40} />
                            <div className="mt-12 text-20 font-700 tracking-1">Portfolio connected</div>
                            <div className="mt-8 text-center text-15 tracking-1">You can close this tab and go back to telegram</div>
                        </div>
                    )}

                    {smallCaseConnected && !smallcaseError && !isComingFromChannel && <HoldingsInfo holdingsData={holdingsData} />}
                </div>
            </DesktopModalNPhoneScreen>
        </>
    );
};
export const HoldingsInfo = ({ holdingsData }) => {
    const allTicks = holdingsData?.orders?.map(({ intrumentToken }) => intrumentToken);
    const ticksData = useTickerData(allTicks);
    const { tickerLastPriceData } = ticksData;

    const holdingCount = holdingsData?.orders?.length;

    return (
        <div className="flex h-full w-full flex-col ">
            <div className="mt-20 px-20 text-18 font-600">
                Imported {holdingsData?.orders?.length} holding{holdingCount > 1 ? 's' : 's'}
            </div>

            <div className="mt-12 flex flex-col gap-20 px-20 py-12" css={listStyle}>
                {holdingsData?.orders?.map((holding) => {
                    const { quantity, tradingSymbol, price: intialPrice, isin, intrumentToken } = holding;
                    const currentPrice = tickerLastPriceData?.[intrumentToken];
                    const totalProfit = (currentPrice - intialPrice) * quantity;
                    const profitPercentage = (totalProfit / (intialPrice * quantity)) * 100;

                    return (
                        <div className="flex gap-12 text-13">
                            <div className="flex pt-4">
                                <EntityImage isin={isin} name={tradingSymbol} />
                            </div>
                            <div className="flex w-full justify-between">
                                <div>
                                    <div>
                                        <span className="font-600">{tradingSymbol}</span> x {formatNumberToHumanReadable(quantity)}
                                    </div>
                                    <div className="mt-4">at ₹{formatNumberToHumanReadable(toDecimal(intialPrice, 2))}</div>
                                </div>

                                <div className="flex flex-col items-end">
                                    <div style={{ color: getColorByValue(totalProfit) }} className="font-600">
                                        ₹{getIndicatorByValue(totalProfit)}
                                        {formatNumberToHumanReadable(toDecimal(totalProfit, 2))} | {getIndicatorByValue(totalProfit)}{' '}
                                        {toDecimal(profitPercentage, 2)}%
                                    </div>
                                    <div className="mt-4">₹{formatNumberToHumanReadable(currentPrice)}</div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            <a
                href={`/login`}
                css={css`
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                `}
            >
                <div className="flex w-full items-center justify-center gap-4 text-16 font-600" css={buttonCSS}>
                    <div>Login to save portfolio</div>
                    <ChevronRight height={16} width={16} />
                </div>
            </a>
        </div>
    );
};

const listStyle = css`
    height: calc(100% - 100px);
    overflow-y: scroll;
    padding-bottom: 100px;
`;

const buttonCSS = css`
    background: blue;
    height: 56px;
    color: #fff;
    background: linear-gradient(180deg, #5667ff 0%, #3145f9 100%), linear-gradient(180deg, #a82be3 0%, #7809bc 100%);
`;
