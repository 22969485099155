import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import { useOnClickOutside } from '@/utils/hooks/dom';

import { CrossIcon } from '@/constants/icons';

export const Portal = ({ children, className = 'modal-root', el = 'div' }) => {
    const [container] = React.useState(() => {
        // This will be executed only on the initial render
        // https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
        return document.createElement(el);
    });

    React.useEffect(() => {
        container.classList.add(className);
        document.body.appendChild(container);
        return () => {
            document.body.removeChild(container);
        };
    }, []);

    return ReactDOM.createPortal(children, container);
};

export default function DesktopModalNPhoneScreen({ children, closeFn = () => {}, showClose = false }: any) {
    const ref = useRef(null);
    useOnClickOutside(ref, () => closeFn(false));

    return (
        <Portal>
            <GreyBackground className=" text-20custom-grey flex flex-col items-center justify-center md:justify-start">
                <Overlay></Overlay>
                {/*// @ts-ignore*/}
                <Card ref={ref} className=" relative flex flex-col items-center justify-start gap-8">
                    {showClose && (
                        <CrossIconBox onClick={closeFn}>
                            <CrossIcon height={20} width={20} />
                        </CrossIconBox>
                    )}
                    {children}
                </Card>
            </GreyBackground>
        </Portal>
    );
}

const CrossIconBox = styled.div`
    position: absolute;
    top: 24px;
    right: 24px;
    cursor: pointer;
`;

const Overlay = styled.div`
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.14);
    backdrop-filter: blur(4px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    @media screen and (max-width: 640px) {
        background: rgba(0, 0, 0, 0);
    }
`;

const Card = styled.div`
    z-index: 1000;
    width: 459px;
    max-width: 100%;
    margin-top: -56px;

    cursor: default;
    height: 544px;

    background: #ffffff;
    outline: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 20px;

    margin-top: -20px;

    overflow: hidden;
    color: #202124;

    box-shadow: 0.5px 0px 5px 6.5px rgba(0, 0, 0, 0.02);

    @media screen and (max-width: 640px) {
        margin-top: 0px;
        outline: 0px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0.5px 0px 5px 6.5px rgba(0, 0, 0, 0);
        border-radius: 0px;
        height: 100vh;
    }
`;

const GreyBackground = styled.div`
    background-size: cover;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 1000;
    min-height: 100vh;
    width: 100%;
`;
