import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';

import { isTrue } from '@/utils/others';

import { setSmallcaseUserBroker } from '@data-center/api/broker';
import { fetchSmallCaseTransaction, fetchSmallcaseHolding, getHoldingsFromSmallcase, initSmallcase } from '@data-center/api/smallcase';
import { SupportedBrokers } from '@data-center/types/broker';

export const SMALLCASE_ERROR_MAP = {
    'Error during transaction': 'Oh! Looks like the sync failed. Request you to please try after sometime.',
    'Internal Server Error': 'Something went wrong on our end! We will fix this soon. Please try after sometime.',
    consent_denied: 'The consent to import holdings was denied. Please try again.',
    webhook_unreachable: 'Something went wrong on our end! Please try after sometime.',
    user_cancelled: 'The consent to import holdings was denied. Please try again.',
};

export const sleepPromise = (time = 2000) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(true);
        }, time);
    });
};
export const useSmallCaseHoldingImport = ({ callback, onError }) => {
    const [smallCaseConnected, setSmallCaseConnected] = useState(false);
    const [smallcaseError, setsmallcaseError] = useState(null);
    const [isFlowLoading, setFlowLoading] = useState(true);
    const [dataFromSmallcase, setDataFromSmallcase] = useState({});

    const { mutate: initiateUserImport } = useMutation(initSmallcase, {
        onSuccess: async (data) => {
            setFlowLoading(true);
            setsmallcaseError(null);
            const { smallcaseAuthToken } = data;
            (window as any).gatewayInstance = new (window as any).scDK({
                gateway: 'investmint',
                smallcaseAuthToken: smallcaseAuthToken,
                config: {
                    isLeprechaun: isTrue(process.env.NEXT_PUBLIC_SMALLCASE_IS_LEPRECHAUN || false),
                    amo: true,
                },
            });
            triggerTransaction();
        },
        onError: (error: any) => {
            console.log('error', error);
            const errorMessage = error?.message;
            setFlowLoading(false);

            setsmallcaseError(SMALLCASE_ERROR_MAP[errorMessage] || errorMessage);

            onError?.(error);
            console.warn(errorMessage);
        },
    });

    const { mutate: triggerTransaction, isLoading: isTriggerTransactionLoading } = useMutation(fetchSmallCaseTransaction, {
        onSuccess: async (data) => {
            const smallCaseResponseData = await (window as any).gatewayInstance.triggerTransaction({
                // ! Use valid transactionId here
                transactionId: data?.transactionId?.data?.transactionId,
            });
            console.log(smallCaseResponseData, 'smallCaseResponseData');
            const { broker, smallcaseAuthToken } = smallCaseResponseData;

            await setSmallcaseUserBroker({
                broker: broker as unknown as SupportedBrokers,
                smallcaseAuthToken,
                holdingsConsent: 'ENABLED',
            });
            await getHoldingsFromSmallcase();
            const holdingsData = await fetchSmallcaseHolding();
            setDataFromSmallcase(holdingsData);
            setFlowLoading(false);
            setSmallCaseConnected(true);
            callback?.();
        },
        onError: (error: any) => {
            const errorMessage = error?.message;
            setFlowLoading(false);
            setsmallcaseError(SMALLCASE_ERROR_MAP[errorMessage] || SMALLCASE_ERROR_MAP['Error during transaction']);

            console.warn(errorMessage);
            onError?.(error);
        },
    });

    return {
        initiateUserImport,
        smallCaseConnected,
        isTriggerTransactionLoading,
        smallcaseError,
        isFlowLoading,
        holdingsData: dataFromSmallcase,
    };
};
